import { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

import "./Testimonials.scss";
import AppWrap from "../../wrapper/AppWrap";
import MotionWrap from "../../wrapper/MotionWrap";
import images from "../../constants/images";

// create testimonial object
const testimonials = [
  {
    _id: "1",
    name: "John Doe",
    company: "Adidas",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.",
    imgurl: images.adidas,
  },
  {
    _id: "2",
    name: "John Doe",
    company: "Google",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.",
    imgurl: images.email,
  },
];

const brands = [
  {
    _id: "1",
    name: "New Balance",
    imgUrl: images.nb,
  },
  {
    _id: "2",
    name: "Google",
    imgUrl: images.bolt,
  },
  {
    _id: "3",
    name: "Adidas",
    imgUrl: images.adidas,
  },
  {
    _id: "4",
    name: "Amazon",
    imgUrl: images.amazon,
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <h2 className="head-text" style={{marginBottom: '100px'}}>
        What our <span>clients</span> say
      </h2>
      {testimonials.length && (
        <>
          <div className="app__testimonial-item app__flex">
            <img
              src={testimonials[currentIndex].imgurl}
              alt={testimonials[currentIndex].name}
            />
            <div className="app__testimonial-content">
              <p className="p-text">{testimonials[currentIndex].feedback}</p>
              <div>
                <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
                <h5 className="p-text">{testimonials[currentIndex].company}</h5>
              </div>
            </div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>

            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand._id}
          >
            <img src={brand.imgUrl} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "testimonials",
  "app__primarybg"
);
