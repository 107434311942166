import React from "react";

export function NavigationDots({ active }: { active: string }) {
  return (
    <div className="app__navigation">
      {["home", "about", "testimonials", "contact"].map((item, index) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={`#${item.toLowerCase()}`}
          className="app__navigation-dot"
          key={`link-${item}`}
          style={active === item ? { backgroundColor: "#313BAC" } : {}}
        />
      ))}
    </div>
  );
}
