// import React, { useState, useEffect } from "react";
import "./About.scss";
import { motion } from "framer-motion";
import images from "../../constants/images";
import AppWrap from "../../wrapper/AppWrap";
// import { client } from "../../client";

const abouts = [
  {
    title: "Web Development",
    description:
      "We have experience in building web applications using React, NextJS, NodeJS, ExpressJS, MongoDB, MySQL, etc.",
    imgUrl: images.about01,
  },
  {
    title: "Mobile Development",
    description:
      "We have experience in building mobile applications using Flutter, React Native, etc.",
    imgUrl: images.about02,
  },
  {
    title: "UI/UX Design",
    description:
      "We have experience in designing UI/UX for web and mobile applications.",
    imgUrl: images.about03,
  },
  {
    title: "Graphic Design",
    description:
      "We have experience in designing logos, banners, posters, etc.",
    imgUrl: images.about04,
  },
];

function About() {
  // const [abouts, setAbouts] = useState<Record<string, string>[]>([]);
  // useEffect(() => {
  //   const query = '*[_type == "about"]';
  //   client.fetch(query).then((data) => {
  //     console.log(data);
  //     setAbouts(data);
  //   });
  // });
  return (
    <>
      <h2 className="head-text">
        We know that <span className="head-text--color"> good service </span>{" "}
        <br /> means <span className="head-text--color"> good experience </span>
      </h2>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            className="app__profile-item"
            key={about.title + index}
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
          >
            <img src={about.imgUrl} alt={about.title} />
            <h2
              className="bold-text"
              style={{
                marginTop: 20,
              }}
            >
              {about.title}
            </h2>
            <p
              className="p-text"
              style={{
                marginTop: 10,
              }}
            >
              {about.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
}

export default AppWrap(About, "about", "About");
