import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import images from "../../constants/images";
import "./Navbar.scss";

function Navbar() {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo2} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {["Home", "About", "Testimonials", "Contact"].map(
          (item, index) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item.toLowerCase()}`}>{item}</a>
            </li>
          )
        )}
      </ul>

      {/* Mobile Devices */}
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            {["Home", "About", "Services", "Testimonials", "Contact"].map(
              (item, index) => (
                <ul>
                  <li key={`link-${item}-mobile`}>
                    <a
                      onClick={() => setToggle(false)}
                      href={`#${item.toLowerCase()}`}
                    >
                      {item}
                    </a>
                  </li>
                </ul>
              )
            )}
          </motion.div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
