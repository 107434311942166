import { createClient } from "@sanity/client";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: process.env.REACT_APP_API_TOKEN,
  dataset: "production",
  apiVersion: "1",
  useCdn: true,
  token: process.env.REACT_APP_PROJECT_ID,
});

const builder = imageUrlBuilder(client);

export const urlFor = (src: SanityImageSource) => builder.image(src);
